import React from 'react';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import { Container } from './content-page.css';

const contentPage = data => {
  return (
    <Layout>
      <Box>
        <Title as="h2" size="large">
          {data.pageContext.title}
        </Title>
        <Container>
          <div dangerouslySetInnerHTML={{ __html: data.pageContext.body }} />
        </Container>
      </Box>
    </Layout>
  );
};

export default contentPage;
